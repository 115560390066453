/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { VideoContainer } from './VideoContainer'
import { devices } from './common/theme'
import { Block } from './Block'

const CardWrapper = styled.div<{withHover?: boolean}>`
  display: block;
  height: auto;
  width: 380px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 1rem;
  border: ${props => `1px solid ${props.theme.colors.black}`};
  background-color: ${props => props.theme.colors.secondaryBlack};
  position: relative;
  z-index: 2;

  :hover {
    border: ${({ withHover, theme }) => withHover && `1px solid ${theme.colors.green}`};
    box-shadow: ${({ withHover }) => withHover && '0 0 1.5rem rgba(0, 170, 120, 0.5)'};
  }

  @media ${devices.mobile} {
    width: 100%;
    max-width: 330px;
  }
`

const CardContent = styled.div`
  position: relative;
  padding: 30px 0;

  @media ${devices.mobile} {
    padding: 20px 0;
  }
`

const CenteredContent = styled.div`
  width: 80%;
  margin: 0 auto;

  @media ${devices.mobile} {
    width: 85%;
  }
`

export const CardTitle = styled.h3`
  font-size: 20px;
  margin: 0;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const CardInfo = styled.p`
  font-size: 16px;
  line-height: 1.5;
  opacity: 80%;

  margin: 0;
  -webkit-line-clamp: 3; 
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  overflow: hidden;
`

export const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
`

export const CollectionCardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
`

export const blink = keyframes`
  from {
    opacity: 0.6;
  }

  to {
    opacity: 1;
  }
`

export const Plug = styled.div<{width?: number, height?: number, mobile?: string}>`
  height: ${({ height }) => height ? `${height}px` : '100%'};
  width: ${({ width }) => width ? `${width }px` : '100%'};
  opacity: 0.6;
  animation: ${blink} 1s ease-in-out infinite;
  background-color: rgba(40, 47, 66, 1);
  margin-bottom: 1rem;

  @media ${devices.mobile} {
    ${({ mobile }) => mobile && `${mobile}`}
  }
`

const VideoWrapper = styled.div`
  width: 380px;
  height: 237px;

  @media ${devices.mobile} {
    width: 330px;
    height: 205px;
  }
`

type DefautlCardProps = {
  imgUrl: string,
  isLandscapeOriented?: boolean,
  children: JSX.Element[] | JSX.Element,
} & React.HTMLAttributes<HTMLDivElement>

export const DefaultCard = (props: DefautlCardProps) => {
  return <CardWrapper {...props} withHover>
    <VideoWrapper>
      <VideoContainer src={props.imgUrl} />
    </VideoWrapper>
    <CardContent>
      <CenteredContent>
        {props.children}
      </CenteredContent>
    </CardContent>
  </CardWrapper>
}

export const CardLoader = () => {
  return <CardWrapper>
    <Plug height={380} />
    <CardContent>
      <CenteredContent>
        <CardInner>
          <Plug height={24} />
          <Plug height={24} width={176} />
          <Plug height={24} width={125} />
          <Block marginTop={36} />
          <Plug height={24} width={176} />
        </CardInner>
      </CenteredContent>
    </CardContent>
  </CardWrapper>
}

export const CardsLoader = ({ length }: {length: number}) => {
  return <>
    {new Array(length).fill('').map((el, i) => (
      <CardLoader key={i} />
    ))}
  </>
}
